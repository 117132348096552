﻿.host-default {
    position:absolute;
    z-index:0;
    top:0;
    left:0;
    width:100%;
    height:100%; 
    background-size:cover; 
    background-repeat:no-repeat;
}

a, a:not([href]) {
	color: $link-color;
}

.scroll-y {
    overflow:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
}

.full-width {
    width:100%;
}

.inline {
    display: inline-block;
}
.display-block {
	display: block !important;
}

.font-bold{
    font-weight:500;
}
.font-bolder {
	font-weight: 600;
}
.text-xl {
    font-size: 23px !important;
}
.text-xxl {
    font-size: 30px;
}

.text-lg {
    font-size: 18px;
}

.text-md {
    font-size: 16px;
}

.text-base {
    font-size: 14px;
}

.text-sm {
    font-size: 13px;
}

.text-xs {
    font-size: 12px;
}

.text-near {
    text-align:left;
}

.text-center{
    text-align:center;
}

.text-far{
    text-align:right;
}
.pull-near {
    float:left;
}
.pull-far{
    float:right;
}

.text-muted{
    opacity:0.7;
}

.text-ileft{
	text-align:left !important;
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.text-accent {
	color: $link-color;
}

.text-strike{
    text-decoration:line-through;
}

.text-pre-wrap{
    white-space:pre-wrap;
}

.text-unavailable {
    text-decoration: line-through !important;
    text-decoration-color: red !important;
}

.small-gutters {
    margin-right: -5px;
    margin-left: -5px;

    > .col,
    > [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
}


.mat-icon.mat-mdc-accent.text-highlight,
.text-highlight {
	color: $highlight-color;
}

.text-white {
	color: white;
}

.no-shadow{
	box-shadow:none !important;
}
.no-wrap{
	white-space:nowrap;
}
.no-margins{
	margin:0 !important;
}
.no-padding{
	padding:0 !important;
}

.ltr-field {
	direction: ltr;
}
.panel-shadow {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.rtl {
    .text-near {
        text-align:right;
    }
    .text-far{
        text-align:left;
    }
    .pull-near {
        float:right;
    }
    .pull-far{
        float:left;
    }

    .px-1{
        padding-left:0.5rem !important;
        padding-right:0.5rem !important;
    }
    .px-2{
        padding-left:1rem !important;
        padding-right:1rem !important;
    }
    .px-3{
        padding-left:1.5rem !important;
        padding-right:1.5rem !important;
    }
}

.no-shadow{
	box-shadow:none !important;
}

.avatar {
    display: inline-block;
    width: 24px;
    min-width:24px;
    height: 24px;
    border-radius: 100%;
    background-color: map-get($default-theme, 0)fff;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.trans-chip{
	background: rgba(245, 245, 245, 0.2);
    padding: 3px 6px;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    margin-left: 6px;
}

.category-chip{
	display:inline-block;
    & + .category-chip:before{
        content:'|';
		display:inline-block;
		padding:0 8px;
	}
}

.separated-list{
    > *{
        display:inline-block;
        &:not(:first-child):before{
            content:'|';
		    display:inline-block;
		    padding:0 8px;
        }
    }
}

.bullet-separated-list{
    >*{
        display:inline-block;
        &:after{
            content:'\2022';
		    display:inline-block;
		    padding:0 4px;
	    }
        &:last-child::after{
            display:none;
        }  
    }
}

.bullet-separated{
	display:inline-block;
	& ~ .bullet-separated:before{
        content:'\2022';
		display:inline-block;
		padding:0 8px;
	}
}

.chip-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;
    >.chip-item{
        background: map-get($default-theme, 0);
        font-size: 12px;
        margin: 3px;
        display: inline-flex;
        padding: 3px 5px;
        border-radius: 6px;
        align-items: center;
        cursor: default;
        min-height: 21px;
        height: 1px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        font-weight: 500;
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
        -webkit-tap-highlight-color: transparent;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        &.accent1{
            background:#3f51b5;
            color:map-get($default-theme, 0);
        }
        &.accent2{
            background:#ff4081;
            color:map-get($default-theme, 0);
        }
    }
}

.pm-badge {
	display: inline-flex;
	margin-left: 10px;
	margin-bottom: 10px;
	height: 26px;
	border-radius: 17.5px;
	border: solid 1px #a6e0db;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	padding: 0 5px;

	> div {
		padding: 0 5px;
		color: map-get($default-theme, 600);
		white-space: nowrap;

		&._cap {
			font-weight: 500;
			max-width: 80px;
		}

		&._tip {
			font-size: 12px;
		}
	}
}

.back-button{
	background-size:contain;
	background-repeat:no-repeat;
	background-position: center;
	cursor:pointer;
    
    &.disabled{
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
	}

}


.wiz-tabs {
	&.full-height {
		height:100%;
		overflow: hidden;
		>.mat-mdc-tab-body-wrapper{
			height:100%;
		}
	}
	&.hide-headers {
		>.mat-mdc-tab-header{
			display:none;
		}
	}
}

.big-dialog-panel {
    // important is used because MatDialog applies css to the elements
    max-width: none !important;
    max-height: none !important;

    width: calc(100% - 5em) !important;
    height: calc(100% - 10em) !important;

    .mat-mdc-dialog-container {
        background: #333;
    }
}

.cdk-overlay-pane.full {
	background: transparent;
	box-sizing: border-box;
	width: 100%;
	max-width: 100% !important;
	height: 100vh;
	max-height: 100%;

	.cordova :host & {
		padding-top: env(safe-area-inset-top, 1rem);
		padding-bottom: env(safe-area-inset-bottom, 1rem);
	}

	.mat-mdc-dialog-container {
		padding: 0;
		margin: 0;
		overflow: hidden;

		.mdc-dialog__surface {
			border-radius: 0;
		}
	}
}

.mat-mdc-select-panel.select-below-panel{
	&._fix-width{
		min-width: calc(100% + 16px) !important;
	}
    margin-top: 39px !important
}

.generic-desc{
	padding: 1rem;
    border:1px solid $separator;
	border-width:1px 0;
    background: map-get($default-theme, 100);

    &.center {
        text-align: center;
    }
}

.mat-mdc-tab-group.generic-tab-group {
    .mat-mdc-tab-header {
        
    }
	.mat-mdc-tab.mdc-tab--active {
		opacity:1;
	}
    .mat-mdc-ink-bar {
        background-color:$primary-text;
    }
    .mat-mdc-tab {
        padding: 0 0.7rem;
    }
}

.input-group-search {
    background: map-get($default-theme, A300);
    border-radius: 2.375rem;
    height: 2.375rem;
    padding: 0 0.5rem 0 0;

    body.ltr & {
        padding: 0 0 0 0.5rem;
    }

	button.btn, .input-group-text,
	> input {
        background:transparent;
		border-width:0 !important;
        color: map-get($default-theme, 700)!important;
        
		&.form-control::placeholder {
			color: map-get($default-theme, 700)!important;
		}
    }
    button.btn {
        padding: 0;
        mat-icon {
            position: relative;
            top: 2px;
        }
    }
}

.generic-list-accordion {
	> mat-expansion-panel {
		background: transparent;
        box-shadow: none !important;
        
        .mat-mdc-list-base {
            margin: -1rem 0 0.5rem 0 !important;
            padding: 0 !important;
        }

		&:not(.mat-expanded) {
			border-bottom: 1px solid $separator;
		}

		.mat-expansion-panel-header:hover {
			background: transparent !important;
		}

		.mat-expansion-panel-header-title {
            font-weight: 500;
            font-size: 1rem;
		}

		.mat-expansion-panel-body {
			padding: 0;
		}
	}
}

.generic-list-group {
	font-weight: 600 !important;
}

.generic-list-nodata {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 8rem;
	color: map-get($default-theme, 400);
}

.generic-list {
	padding-top: 0;

	&.generic-list-boxed {
		padding: 0 15px;

		.generic-list-item {
			@include card-shadow;
			height: 75px !important;
			margin-bottom: 15px;
			background: map-get($default-theme, 0);
			border-radius: 5px !important;

			&:before {
				display: none;
			}

			> .mat-list-item-content {
				padding: 0;
			}

			.mat-mdc-list-item-avatar {
				width: 75px !important;
				height: 75px !important;
				border-radius: 0 5px 5px 0 !important;
			}

			.mat-mdc-list-text {
				._title {
					font-size: 1.2rem;
					line-height: 22px;
					font-weight: 500;
				}
			}
		}
	}

	.generic-list-item {
		position: relative;
		height: 6rem !important;

		&:not(.single):after {
			content: '';
			position: absolute;
			border-top: 1px solid;
			border-color: $separator;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&.mat-mdc-2-line {
			height: 5.5rem;
		}

		.mat-list-item-content {
		
		}

		.mat-mdc-list-item-avatar {
			width: 4rem !important;
			height: 4rem !important;
			border-radius: 0.5rem !important;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			overflow: hidden;
			position: relative;
			&._icon {
				width:50px !important;
				background-color: transparent;
				background-size:40px;
			}
		}
		 .mdc-list-item__primary-text { 
			._title {
				font-size: 1.2rem;
				line-height: 1.5rem;
				font-weight: 500;
				width: calc(100% - 2rem);
			}
		 }

		.mat-mdc-list-icon {
			background: map-get($default-theme, 0);
			width: 50px !important;
			height: 50px !important;
			text-align: center;
            line-height: 1;
			font-size: 30px !important;
            padding: 10px !important;
            box-sizing: border-box !important;
		}

		.mdc-list-item__primary-text {
			h3 {
				margin: 0 0 0 0;
				font-weight: 500;
				font-size: 18px;

				&._favorite {
					color: $dark-accent-color;
				}
			}

			._header {
			}

			._title {
				font-size: 1.2rem;
				line-height: 1.5rem;
				font-weight: 500;
				width: calc(100% - 2rem);
			}

			._description {
				font-size: 1rem;
				color: mat-color($default-theme, 800);
			}

			._details {
				color: mat-color($default-theme, 600);
				height: 1.5rem;

				> * {
					font-size: 1rem;
				}
			}

			.chip.distance {
				background: mat-color($default-theme, 100);
				padding: 3px 6px;
				border-radius: 4px;
				display: inline-block;
				margin-left: 6px;
			}

			.mat-mdc-line._history {
				margin-top: 4px;
				font-size: 12px;
				color: $dark-accent-color;
			}
		}
	}
}

.dashboard-panel {
	width: 100%;
	overflow: hidden;

	> .dashboard-panel-body {
		width: 100%;
		padding: 0 1rem 0.8rem 1rem;
		overflow-x: auto;
		overflow-y: hidden;
		
		> .card-deck {
            display: flex;
            flex-flow: row nowrap;
			overflow: visible;
			-webkit-overflow-scrolling: touch;

			> .card {
                position: relative;
                overflow: hidden;
				border-width: 0;
				min-width: 17rem;
				min-height: 14rem;
				margin-bottom: 0;
				background-color: $card-background;
				border-radius: 0.5rem;
				@include card-shadow;
				@include highlight-background;

				> .card-img-top {
					height: 8rem;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					box-shadow: inset 1px 1px 1px rgba(255,255,255,0.1);
					position: relative;
					overflow: hidden;

					> ._favorite {
						position: absolute;
						left: 0.5rem;
						bottom: 0.5rem;
					}
				}

				> .card-body {
					padding: 10px 10px 0 10px;

					.card-title {
						font-size: 1.4rem;
						line-height:1.4rem;
						font-weight:400;
						margin-bottom: 0;
                    }
                    
                    .card-categories {
                        display: none; // We show the Card Categories only on Tabit Web
                    }

					.card-description, .card-tags {
						color: map-get($default-theme, 600);
					}

				}

				> .card-footer {
					background: transparent;
					border-width: 0;
					padding: 12px 10px;
					font-size: 1rem;

					.card-distance {
						color: $primary-text;
						font-weight:500;
					}

					.card-address {
						color: map-get($default-theme, 1000);
					}

					.separator {
						margin: 0 0.5rem;
						color: $separator;
					}
				}

                .tabit-badge {
                    display: inline-block;
					height: 25px;
					line-height: 25px;
					padding: 0 0 0 0.3rem;
                }

				&.card-site {
					background-repeat: no-repeat;
					background-size: cover;
					background-blend-mode: lighten;
				}

			}
		}
	}
}

.mat-mdc-nav-list.mat-mdc-nav-list-bottom {
	@include cordova-margin-bottom;
}

.text-dark-accent{
    color: $dark-accent-color !important;
}

google-map{ 
    height: 100%;
}

.site-avatar-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.closed-now-overlay {
    width:100%;
    height:100%;
	background-color: rgba(black, 0.3);
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
	color: white;
	text-shadow: 0 0 2rem black;
	small{display:block}
    &.sm > div{
        padding: 0 5px;
        line-height:13px;
        font-size:12px;
        font-weight:500;
    }
    &.md > div{
        font-size:20px;
    }
}

.accordion-container {
	mat-accordion {
		margin: 0;

		> mat-expansion-panel {
            box-shadow: 0 0 0 1px rgba(0,0,0,0.05) !important;
            border-radius: 0;

			.mat-expansion-panel-header-title {
				
			}

			mat-form-field {
				width: 100%
			}

			&.no-pad {
				.mat-expansion-panel-body {
					padding: 0 0;
				}
			}
		}
	}
}

.my-panel {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09) !important;
	background: map-get($default-theme, 0);
	border-radius: 4px;

	&.panel-shadow {
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	}

	> .my-panel-header {
		height: 64px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 24px;
		border-radius: inherit;
		font-size: 15px;
		font-weight: 600;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09)
	}

	> .my-panel-body {
	}
}

.keyboard-spacer {
    height: 45vh;
}

.spacer-bottom {
	//padding-bottom:120px;
}
.spacer-bottom-small {
	padding-bottom: $bottombar-height;
	@include cordova-margin-bottom;
}

.animation-dialog {
	mat-dialog-container {
		// background:rgba(255,255,255,1);
		.mat-mdc-dialog-title{
			margin:0;
		}
		border-radius:15px !important;

		button{
			min-width:120px;
		}
	}
}

.sign-in-dialog {
	mat-dialog-container {
        background: grey;
        @include card-shadow;
		.mat-mdc-dialog-title {
			margin:0;
		}
		border-radius:15px !important;
	}
}

.dialog-backdrop-dark {
    background-color: rgba(0,0,0,0.7);
}

.dialog-backdrop-darker {
    background-color: rgba(0,0,0,0.9);
}

.animation-dialog-dark {
    .mat-mdc-dialog-container {
        background-color: transparent;
        box-shadow: none;

        .mat-mdc-dialog-content {
            -webkit-appearance: initial; // Important Note: if this override is not used, then in Safari this element will NOT have a transparent background.
        }

        .mat-mdc-dialog-title {
            margin: 0;
        }
        
        button {
            min-width: 120px;
        }
    }
}

.to-loyalty-dialog {
	// overflow: hidden;

	mat-dialog-container {
		position: relative;
		border-radius: 0 30px 30px 0;
		padding: 0;
		overflow: hidden;
		background: transparent;

		.to-loyalty-container.no-info {
			background: map-get($primary-theme, 100);
		}

        body.ltr & {
            border-radius: 30px 0 0 30px !important;
        }

		.mat-mdc-dialog-content {
			max-height: 85vh;
			overflow: hidden;
			padding: 0;
		}
	}
}

.to-basket-dialog {
	position: absolute !important;
	bottom: 0;

	mat-dialog-container {
		padding: 30px 16px 0;
		border-radius: 30px 30px 0 0 !important;
		border: 1px solid var(--primary-theme-500);
	}
}

.rounded-dialog {
    mat-dialog-container {
		border-radius: 5px !important;
        position: relative;
        overflow: visible;
		
        .mat-mdc-dialog-content {
			padding: 0;
        }
		
		.mat-mdc-dialog-component-host {
			padding: 0;
		}
		
		.mat-mdc-dialog-surface {
			padding: 5px;
		}
    }
}

.ex-pay-dialog {
	mat-dialog-container {
        height: 100vh;
		border-radius: 5px !important;
		position: relative;
		overflow: visible;
		padding: 0 5px;

		.mat-mdc-dialog-title{
			padding: 10px 5px;
			margin-bottom:0;
		}

		.mat-mdc-dialog-content {}
	}

	._desktop & {
		width: 1005px !important;
	}
}

.round-dialog {
    mat-dialog-container {
        @include card-shadow;
        @include highlight-background;
        border-radius: 1rem !important;
        position: relative;
        overflow:visible;

        .mat-mdc-dialog-content {
            max-height: 80vh;
        }
    }
}

.fullscreen-dialog {
	width: 100%;
	max-width: 100% !important;
	height: 100vh;
	max-height: 100% !important;

	margin-right: 50%;
    margin-left: 50%;

	mat-dialog-container {
		@include main-dialog-style;
		max-width: 600px;
		max-height: none;

		.mat-mdc-dialog-content {
			max-height: calc(100% - 40px);
		}
	}
}

.gallery-dialog {
	width: 50%;
	// height: 50vh;
    justify-content: center;

    body._mobile & {
        width: 90%;
        max-height: 600px;
    }

	mat-dialog-container {
		@include main-dialog-style;
		max-width: 600px;
        padding: 0;

		.mat-mdc-dialog-content {
			max-height: calc(100% - 40px);
		}
	}
}

.fullscreen-no-background-dialog {
	width: 100%;
	max-width: 100% !important;
	height: 100vh;
	max-height: 100% !important;

	.mat-mdc-dialog-container .mdc-dialog__surface {
		background-color: transparent !important;
	}

	mat-dialog-container {
        background-color: transparent !important;
		max-width: none;
		max-height: none;

		.mat-mdc-dialog-content {
			max-height: calc(100% - 40px);
		}
	}
}

.fullscreen-background-dialog {
    width: 100%;
    max-width: 100% !important;
    height: 100vh;
    max-height: 95% !important;

    mat-dialog-container {
        max-width: none;
        max-height: none;

        .mat-mdc-dialog-content {
            max-height: 75%;
        }
    }
}

/*
* Material Palletes:
* https://material.io/guidelines/style/color.html#color-color-palette
*/
@use '@angular/material' as mat;

@import 'matTheme.scss';
//@include mat-core();

/* LOYALTY LIGHT THEME */

:root {

    --mdc-chip-label-text-font: 'Noto Sans Hebrew','sans-serif';

    //.mat-mdc-button letter-spacing
    --mdc-text-button-label-text-tracking: 0;
    --mdc-outlined-button-label-text-tracking: 0;
    --mdc-outlined-text-field-label-text-tracking: 0;
    --mdc-form-field-label-text-tracking: 0;
    --mdc-outlined-text-field-label-text-size: 15.1px;
    //.mat-mdc-unelevated-button letter-spacing
    --mdc-filled-button-label-text-tracking: 0;

    //.mat-mdc-dialog-container border-radius
    --mdc-dialog-container-shape:15px;

    --dark-primary-text: rgba(black, 0.87);
    --light-primary-text: #FFF;

    --primary-theme-50: #e5f9ff;
    --primary-theme-100: #A6E3E4;
    --primary-theme-200: #94e6ff;
    --primary-theme-300: #69dcff;
    --primary-theme-400: #49d5ff;
    --primary-theme-500: #0CA3A6;
    --primary-theme-600: #24c8ff;
    --primary-theme-700: #1fc1ff;
    --primary-theme-800: #19baff;
    --primary-theme-900: #0faeff;
    --primary-theme-1000: #e5f6f7;
    --primary-theme-1100: #e5f6f7;
    --primary-theme-A100: #ffffff;
    --primary-theme-A200: #f7fcff;
    --primary-theme-A400: #c4eaff;
    --primary-theme-A700: #abe0ff;
    --primary-theme-A800: #0c5460;
    --primary-theme-O020: rgba(0, 200, 201, 0.2);
    --primary-theme-O080: rgba(0, 200, 201, 0.8);

    --theme-0: #FFF;
    --theme-50: #FAFAFA;
    --theme-100: #F5F5F5;
    --theme-150: #EFF2F3;
    --theme-200: #EEE;
    --theme-300: #E0E0E0;
    --theme-350: #DDD;
    --theme-400: #BDBDBD;
    --theme-500: #9E9E9E;
    --theme-600: #757575;
    --theme-700: #616161;
    --theme-800: #424242;
    --theme-900: #212121;
    --theme-1000: #000;
    --theme-A10: #FFF;
    --theme-A100: #FFF;
    --theme-A200: #EEE;
    --theme-A300: #EDEDED;
    --theme-A400: #BDBDBD;
    --theme-A700: #616161;
    --theme-A800: #0c5460;
    --theme-O1: rgba(0,0,0,0.1);
    --theme-O5: rgba(0,0,0,0.5);
    --theme-O8: rgba(0,0,0,0.8);

    // custom color palettes
    --palette-neutral-0: #fff;
    --palette-neutral-10: #f7f7f7;
    --palette-neutral-20: #ECECEC;
    --palette-neutral-30: #D3D3D3;
    --palette-neutral-40: #C3C3C3;
    --palette-neutral-50: #9F9F9F;
    --palette-neutral-60: #7F7F7F;
    --palette-neutral-70: #666666;
    --palette-neutral-75: #525252;
    --palette-neutral-80: #474747;
    --palette-neutral-90: #333333;
    --palette-neutral-95: #31393b;
    --palette-neutral-100: #222222;
    --palette-neutral-110: #1e2324;
    --palette-neutral-120: #141414;

    --palette-turquoise-10: #e3f9f9;
    --palette-turquoise-70: #E8FAFA;
    --palette-turquoise-80: #D7F7F7;
    --palette-turquoise-90: #AEE9EB;
    --palette-turquoise-100: #00C3C7;
    --palette-turquoise-110: #11A6A8;
    --palette-turquoise-150: #253c3d;

    --palette-red-20: #FFEBEB;
    --palette-red-100: #FF5959;
    --palette-red-160: #3d2b2c;

    --translate100: -100%;
    --translate0: 100%;
}

// it was decided to prevent any dark-theme style if custom-branding is applied in TO.
// due to Material's top-tier CSS injections, we need to apply globally a "default" state to the DOM
#default-branding {
    @media (prefers-color-scheme: dark) {
        --theme-0: #000;
        --theme-50: #212121;
        --theme-100: #424242;
        --theme-1000: #FFF;
        --theme-150: #303030;
        --theme-200: #616161;
        --theme-300: #757575;
        --theme-350: #888;
        --theme-400: #9E9E9E;
        --theme-500: #BDBDBD;
        --theme-600: #E0E0E0;
        --theme-700: #EEE;
        --theme-800: #F5F5F5;
        --theme-900: #FAFAFA;
        --theme-A10: #2A2A2A;
        --theme-A100: #616161;
        --theme-A200: #BDBDBD;
        --theme-A300: #444;
        --theme-A400: #EEE;
        --theme-A700: #FFF;
        --theme-A800: #A6E3E4;
        --theme-O1: rgba(255,255,255,0.1);
        --theme-O5: rgba(255,255,255,0.5);
        --theme-O8: rgba(255,255,255,0.8);
    }
}

$default-theme: (
  0: var(--theme-0),
  50: var(--theme-50),
  100: var(--theme-100),
  150: var(--theme-150),
  200: var(--theme-200),
  300: var(--theme-300),
  350: var(--theme-350),
  400: var(--theme-400),
  500: var(--theme-500),
  600: var(--theme-600),
  700: var(--theme-700),
  800: var(--theme-800),
  900: var(--theme-900),
  1000: var(--theme-1000),
  A10: var(--theme-A10),
  A100: var(--theme-A100),
  A200: var(--theme-A200),
  A300: var(--theme-A300),
  A400: var(--theme-A400),
  A700: var(--theme-A700),
  O1: var(--theme-O1),
  O5: var(--theme-O5),
  O8: var(--theme-O8),
  contrast: (
    50: var(--dark-primary-text),
    100: var(--dark-primary-text),
    200: var(--dark-primary-text),
    300: var(--dark-primary-text),
    400: var(--dark-primary-text),
    500: var(--dark-primary-text),
    600: var(--light-primary-text),
    700: var(--light-primary-text),
    800: var(--light-primary-text),
    900: var(--light-primary-text),
    A100: var(--dark-primary-text),
    A200: var(--dark-primary-text),
    A400: var(--dark-primary-text),
    A700: var(--light-primary-text),
  )
);

$primary-theme:(
    0: #000,
    50: var(--primary-theme-50),
    100: var(--primary-theme-100),
    200: var(--primary-theme-200),
    300: var(--primary-theme-300),
    400: var(--primary-theme-400),
    500: var(--primary-theme-500),
    600: var(--primary-theme-600),
    700: var(--primary-theme-700),
    800: var(--primary-theme-800),
    900: var(--primary-theme-900),
    1000: var(--primary-theme-1000),
    1100: var(--primary-theme-1000),
    A100: var(--primary-theme-A100),
    A200: var(--primary-theme-A200),
    A400: var(--primary-theme-A400),
    A700: var(--primary-theme-A700),
    O020: var(--primary-theme-O020),
    O080: var(--primary-theme-O080),
    contrast:(
        50: #000000,
        100: #000000,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    )
);

$palette-neutral: (
    0: var(--palette-neutral-0),
    10: var(--palette-neutral-10),
    20: var(--palette-neutral-20),
    30: var(--palette-neutral-30),
    40: var(--palette-neutral-40),
    50: var(--palette-neutral-50),
    60: var(--palette-neutral-60),
    70: var(--palette-neutral-70),
    80: var(--palette-neutral-80),
    90: var(--palette-neutral-90),
    95: var(--palette-neutral-95),
    100: var(--palette-neutral-100),
    110: var(--palette-neutral-110),
    120: var(--palette-neutral-120)
);

$palette-turquoise: (
    10: var(--palette-turquoise-10),
    70: var(--palette-turquoise-70),
    80: var(--palette-turquoise-80),
    90: var(--palette-turquoise-90),
    100: var(--palette-turquoise-100),
    110: var(--palette-turquoise-110),
    150: var(--palette-turquoise-150),
);

$palette-red: (
    20: var(--palette-red-20),
    100: var(--palette-red-100),
    160: var(--palette-red-160),
);

:host-context(body.ltr) {
    --translate100: 100%;
    --translate0: -100%;
}

$primary-text: map-get($primary-theme, 500);
$primary-background: map-get($primary-theme, 500);
$primary-background-light: map-get($primary-theme, 100);

$light-primary:mat.define-palette($primary-theme);
$light-accent:mat.define-palette($default-theme, 900, 600, 400);
$light-warn:mat.define-palette(mat.$deep-orange-palette, A200);
$light-theme:mat.define-light-theme(
    (
        color:(
            primary:$light-primary,
            accent:$light-accent,
            warn:$light-warn
        )
    )
);

$dark-primary:mat.define-palette($primary-theme);
$dark-accent:mat.define-palette($primary-theme, A200, A100, A400);
$dark-warn:mat.define-palette(mat.$deep-orange-palette);
$dark-theme:mat.define-dark-theme(
    (
        color:(
            primary:$dark-primary,
            accent:$dark-accent,
            warn:$dark-warn
        )
    )
);

$alert-background:#DC8A92;
$alert-background-light: #DC8A92; //rgba(215, 53, 69, 0.2);
$alert-text:#D73545;

$dark-accent-color: mat.get-color-from-palette($dark-accent);

$toolbar-height: 63px;
$bottombar-height: max(60px, calc(80px - (env(safe-area-inset-bottom, 0))));

$link-color: $primary-text;
$highlight-color: #ff3a5f;

$background-default: map-get($default-theme, 0);
$background-light: map-get($default-theme, 50);
$background-light-with-opacity: map-get($default-theme, 50); // rgba(250,250,250,0.9);
$background-dark: map-get($default-theme, 600);
$background-dark-with-opacity: map-get($default-theme, 900); // rgba(0,0,0,0.3);
$background-highlight: map-get($default-theme, A300);

$card-background: map-get($default-theme, A10);
$absolute-grey: #757575; // Need this specific color for BOTH Dark Mode and Light Mode
$separator: map-get($default-theme, A300);

$menu-item-padding: 0.85rem;
$menu-item-half-padding: 0.425rem;
$menu-item-border-radius: 20px;

$offer-remarks-height: 7rem;
$offer-item-padding: 0.85rem;
$offer-item-divider: 0.55rem;

$accessibility-outline-color:#3276d1 !important;

@mixin router-outlet-view {
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-light;
    overflow: hidden;
}

@mixin title-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07), 0 2px 9px 0 rgba(0, 0, 0, 0.11);
}

@mixin card-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07), 0 2px 9px 0 rgba(0, 0, 0, 0.11);
}

@mixin card-deep-shadow {
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.07), 0 2px 9px 3px rgba(0, 0, 0, 0.9);
}

@mixin button {
    min-height: 3rem;
    min-width: 6.5rem;
    padding: 0 2rem;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    background: map-get($primary-theme, 500);
    border: none;
    border-radius: 1.75rem;
    cursor: pointer;

    mat-spinner {
        display: inline-block;
    }
}

@mixin highlight-background($time: 600ms, $focus-within: 200ms) {
    transition: background-color $time ease-in-out;
    transition-delay: $time;

    // [ ! ] Important
    // The :not(:focus-within) is important in order to prevent the "parent" element from getting "highlighted" when clicking an inner element (such as the time-slots).
    // The focus-within works because we add tabindex="-1" to the inner elements (such as the time-slot and its children)
    // Reference: https://stackoverflow.com/questions/33114318/css-prevent-parent-element-getting-active-pseudoclass-when-child-element-is-cl
    &:active:not(:focus-within) {
        background-color: map-get($default-theme, O1);
        transition: background-color $focus-within ease-in-out;
        transition-delay: 1ms; // Delay one cycle to allow child to focus
    }
}

@mixin transparent-background-with-animation {
    transition: background-color 600ms ease-in-out; // We need to let the router-outlet "leaver" to disappear first (otherwise we might see the leaving component in the background)
    background-color: transparent; // Needed for the scanner plugin to show the camera view in the background.
}

@mixin highlight-by-opacity {
    opacity: 1;
    transition: opacity 600ms ease-in-out;
    // transition-delay: 600ms;

    &:active {
        opacity: 0.6;
        transition: none;
        transition-delay: 0s;
    }
}

@mixin disabled-by-opacity {
    opacity: 0.4;
    cursor: default;
}

// @mixin ios-cordova-notch-margin {
//     margin-top:env(safe-area-inset-top, 35px);
// }

@mixin cordova-dialog-margin-top {
    margin-top:$toolbar-height;
    .cordova & {
        margin-top:calc(#{$toolbar-height} + env(safe-area-inset-top, 35px))!important;
    }
    .cordova :host & {
        margin-top:calc(#{$toolbar-height} + env(safe-area-inset-top, 35px))!important;
    }
    .cordova.android & {
        margin-top:calc(#{$toolbar-height} + 35px)!important;
    }
    .cordova.android :host & {
        margin-top:calc(#{$toolbar-height} + 35px)!important;
    }
}

@mixin cordova-margin-top {
    .cordova & {
        margin-top:env(safe-area-inset-top, 35px);
    }
    .cordova :host & {
        margin-top:env(safe-area-inset-top, 35px);
    }
    .cordova.android & {
        margin-top:35px;
    }
    .cordova.android :host & {
        margin-top:35px;
    }
}

@mixin cordova-padding-top {
    .cordova & {
        padding-top:env(safe-area-inset-top, 35px);
    }
    .cordova :host & {
        padding-top:env(safe-area-inset-top, 35px);
    }
    .cordova.android & {
        padding-top:35px;
    }
    .cordova.android :host & {
        padding-top:35px;
    }
}

@mixin cordova-margin-bottom {
    .cordova & {
        // The Safe Area Inset Bottom is too large on iOS, so we use 50% of it (note we multiple and not add/subtract) because we want also to support Apple devices that don't have a bottom inset (e.g. iPhone 8)
        margin-bottom: calc(env(safe-area-inset-bottom) * 0.5);
    }
    .cordova :host & {
        // The Safe Area Inset Bottom is too large on iOS, so we use 50% of it (note we multiple and not add/subtract) because we want also to support Apple devices that don't have a bottom inset (e.g. iPhone 8)
        margin-bottom: calc(env(safe-area-inset-bottom) * 0.5);
    }
    .cordova.android & {
        margin-bottom:0;
    }
    .cordova.android :host & {
        margin-bottom:0;
    }
}

@mixin cordova-padding-bottom {
    .cordova & {
        // Notice the inset we use here is DIFFERENT than the margin we use at cordova-margin-bottom
        padding-bottom: env(safe-area-inset-bottom);
    }
    .cordova :host & {
        // Notice the inset we use here is DIFFERENT than the margin we use at cordova-margin-bottom
        padding-bottom: env(safe-area-inset-bottom);
    }
    .cordova.android & {
        padding-bottom:0;
    }
    .cordova.android :host & {
        padding-bottom:0;
    }
}

@mixin cordova-module-content {
    .cordova & {
        padding-top: calc(#{$toolbar-height} + env(safe-area-inset-top, 35px));
        padding-bottom: calc(#{$bottombar-height} + (env(safe-area-inset-bottom)));

        &.exclude-padding {
            padding-bottom:0 !important;
        }
    }
    .cordova :host & {
        padding-top:calc(#{$toolbar-height} + env(safe-area-inset-top, 35px));
        padding-bottom:calc(#{$bottombar-height} + (env(safe-area-inset-bottom)));
        &.exclude-padding {
            padding-bottom:0 !important;
        }
    }
    .cordova.android & {
        padding-top:calc(#{$toolbar-height} + 35px);
        // Needed to remove it because the bottom toolbar was deprecated for Tabit Order
        padding-bottom:calc(#{$bottombar-height} + 35px);
        &.exclude-padding {
            padding-bottom:0 !important;
        }
    }
    .cordova.android :host & {
        padding-top:calc(#{$toolbar-height} + 35px);
        // Needed to remove it because the bottom toolbar was deprecated for Tabit Order
        padding-bottom:calc(#{$bottombar-height} + 35px);
        &.exclude-padding {
            padding-bottom:0 !important;
        }
    }
}

@mixin cordova-drop-filters {
    .cordova & {
        top:calc(#{$toolbar-height} + env(safe-area-inset-top, 35px));
    }
    .cordova :host & {
        top:calc(#{$toolbar-height} + env(safe-area-inset-top, 35px));
    }
    .cordova.android & {
        top:calc(#{$toolbar-height} + 35px);
    }
    .cordova.android :host & {
        top:calc(#{$toolbar-height} + 35px);
    }
}

@mixin dashboard-panel-header {
    //position: -webkit-sticky;
    //position: sticky;
    top: 0px;
    background: $background-light-with-opacity;
    z-index:1;
    padding: 10px 15px 0 15px;

    > .title {
        font-size:18px;
        font-weight:600;
    }

    > .more {
        font-weight:500;
        font-size:18px;
        color:$primary-text;
        cursor: pointer;
    }
}

@mixin description-gradient-overlay {
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));

    :host-context(#default-branding) & {
        @media (prefers-color-scheme: dark) {
            background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
        }
    }
}

@mixin toolbar-map-background {
    background: rgba(255,255,255,0.8);

    :host-context(#default-branding) & {
        @media (prefers-color-scheme: dark) {
            background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.2));
        }
    }
}

@mixin toolbar-site-background {
    background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0));
}

@mixin search-spacer {
    height: 5.8rem;

    &.with-address {
        height: 8rem;
    }
}

@mixin nav-header {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 60px;
    background: var(--primary-theme-1100);
    text-align: center;
    display: flex;
}

@mixin main-dialog-border-radius {
    border-radius: 15px;
}

@mixin main-dialog-default-button {
    
    height: 2.75rem;
    width: 100%;
    line-height: 2.75rem;
    font-size: 1.1rem;
    font-weight: 100;
    border-radius: 25px;

    .mdc-button__label {
        line-height: 2.75rem;
        letter-spacing: normal !important;
    }
}

@mixin main-dialog-enforced-style {
    display: flex;
    flex-direction: column;

    .close-button-container {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;

        button {
            padding-inline-start: 1.25rem;
        }
    }

    form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .mat-mdc-dialog-title {
            // margin: 0;
            font-size: 23px;
            line-height: 30px;
        }

        .mat-mdc-dialog-actions {
            min-height: initial;
            margin: 0;
            padding: 0;
        }

        .mat-mdc-dialog-content {
            margin: 0;
            padding: 0;
            max-height: initial;

            mat-form-field {
                &.main-dialog-select-list ::ng-deep {
                    width: 100%;

                    .mat-mdc-form-field-infix {
                        padding: 0.5em 0 1em 0;
                        border-top: 0.84375em solid transparent;

                        mat-select .mat-mdc-select-trigger {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .mat-mdc-select-value {
                                max-width: initial;

                                .mat-mdc-select-value-text {
                                    font-size: 20px;
                                }
                            }

                            .mat-mdc-select-arrow-wrapper {
                                height: 1.5rem;
                                width: 1.5rem;
                                display: flex;
                                align-items: center;
                                transform: initial;
                                font-size: 1.5rem;
                                margin-inline-start: 0.5rem;

                                @media (prefers-color-scheme: light) {
                                    color:  map-get($primary-theme, 500);
                                }

                                .mat-mdc-select-arrow {
                                    display: none;
                                }
                            }

                            .mat-mdc-select-arrow-wrapper:after  {
                                height: 0.6rem;
                                width: 0.6rem;
                                transform: rotate(135deg);
                                display: flex;
                                position: relative;
                                top: -3px;
                                left: 0.15rem;
                                content: '';
                                margin-inline-start: 0.75rem;
                                border-style: solid;
                                border-width: 0.2rem 0.2rem 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin main-dialog-style {
    @include highlight-background;
    @include main-dialog-border-radius;

    @media (prefers-color-scheme: light) {
        background: transparent;
    }
    button {
        letter-spacing: normal;
    }
}

/* WEB VARIABLES */

$web-header-tabs-height: 5rem;
$web-header-explicit-background: rgba(50, 50, 50, 0.9);

@mixin web-container-inner-width {
    width: 100%;
    max-width: 1200px;
    margin:0 auto;

    :host-context(body.ltr) & {
        max-width: 1500px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        // On iPhone landscape and iPads portrait - the 1200px is too wide - so we need to use 80vw as max width.
        max-width: 90vw;
    }
}

@mixin web-container-article-inner-width {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}

@mixin web-header-border-radius {
   border-radius: 1.75rem;
}

@mixin order-step-scroll-parent {
    height: initial !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    margin: 1.5rem 0.5rem;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5rem;
}

@mixin web-mobile-tabs-sidenav-toggler {
    box-sizing: content-box;
    z-index: 1;
    background: none;
    width: 2rem;
    min-width: 0;
    height: 5rem;
    line-height: 5rem;
    margin: 0 -1rem;
    padding: 0 1rem;
    font-size: 2rem;
    color: map-get($primary-theme, 500);
    cursor: pointer;
}

@mixin web-loading-container {
    padding:10rem;
    display:flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
}

@mixin card-container() {
    position: relative;
    background: $background-default;
    // border-radius: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .07), 0 2px 9px 0 rgba(0, 0, 0, .11);
    display: flex;
}

@mixin background-glass-effect {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(3px);
}

@mixin background-glass-effect-child-layout {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    position: relative;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
}

@mixin background-matte-glass-effect {
    background-color: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(3px);
}

@mixin background-light-matte-effect {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2));
    backdrop-filter: blur(2px);
}

@mixin vertical-card-container {

}

.stick-left {
    position: absolute !important;
    left: 0;
}

.stick-right {
    position: absolute !important;
    right: 0;
}

@mixin horizontal-card-container {

}

@mixin slide-animation {

}

// todo - add vertical and horiz...height, width...

@mixin vertical-text-wrapper($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin card-header {
    font-size: 0.95rem;
    @include vertical-text-wrapper(1)
}

@mixin card-description($lines, $color: map-get($default-theme, 700)) {
    font-size: 0.85rem;
    line-height: 1.1rem;
    color: $color;
    text-align: flex-start;
    @include vertical-text-wrapper($lines)
}

@mixin card-image {
    height: 100%;
    width: 40%;
    min-width: 40%;
    border-radius: 0;
    max-width: 40%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin card-tabit-symbol {
    position: absolute;
    top: 0;
    right: 0;
}

@mixin to-navigation-arrows {
    box-shadow: 0 0 19px 1px rgba(0,0,0,0.14);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 0 10px 10px;
    cursor: pointer;
    user-select: none;
}

@mixin circle-background($size, $color) {
    width: $size;
    height: $size;
    background: $color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin main-button {
    background-color: map-get($primary-theme, 500);
    border: none;
    border-radius: 6px;
    color: #000;

    &:disabled {
        // background-color: $separator;
        opacity: 0.6;
        cursor: default;
        color: var(--theme-400);
    }
}

@mixin to-promotion-card {
    // margin: 30px;
    display: flex;
    justify-content: center;
    border: none;
    background-color: map-get($primary-theme, 100);
    color: #000000;
    border-radius: 0;
    align-items: center;
    text-align: center;
    height: fit-content;
    min-height: 50px;

    * {
        margin: 0 5px;
    }
}

@mixin to-messages-card {
    display: flex;
    justify-content: center;
    border: none;
    color: #000000;
    border-radius: 0;
    align-items: center;
    text-align: center;
}

@mixin to-checkout-selection-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 15px;
    padding: 0.75rem 0;
    cursor: pointer;
    background: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    border: 1px solid map-get($primary-theme, 100);
    box-shadow: 0 0 33px 0 rgba(0, 0, 0, 0.12), 4px 2px 9px 0 rgba(130, 178, 179, 0.51);

    :host-context(#default-branding) {
        @media (prefers-color-scheme: dark) {
            color: #000;
        }
    }
}

@mixin to-checkout-selected-option {
    background: map-get($primary-theme, 50);
    border: 2px solid map-get($primary-theme, 500);
    font-size: x-large;
    font-weight: bold;
    animation: scale-up-down 0.3s forwards;

    :host-context(#default-branding) {
        @media (prefers-color-scheme: dark) {
            color: #000;
        }
    }

    @keyframes scale-up-down {
        0% {transform: scale3d(1, 1, 1)}
        50% {transform: scale3d(1.1, 1.1, 1.1)}
        100% {transform: scale3d(1.05, 1.05, 1.05)}
    }
}

@mixin to-sidemenu-caption-container {
    mat-icon {
        color: white;
        margin: 0 0 0 1rem;

        :host-context(body.ltr) {
            margin: 0 1rem 0 0;
        }
    }

    .button-caption {
        color: white;
        font-size: 1rem;
        font-weight: 200;
    }
}

@mixin light-border {
    border: 0.2px solid white;
}

@mixin stretched-side-button-color {
    @include light-border;
    border-right: none;
    background: map-get($primary-theme, 500);
    color: #ffffff;
    border-right: none;
}

@mixin stretched-from-side-button {
    .service-button {
        @include stretched-side-button-color;
        @include card-shadow;
        opacity: 0.85;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 50px 0 0 50px;
        padding: 0.8rem;
        font-size: large;
        font-weight: 500;
        margin-bottom: 1rem !important;
        width: 100%;

        &:active, &:hover {
            opacity: 1 !important;
        }

        &.extended {
            flex-flow: column;
        }

        .delayed-services-container {
            display: flex;
            flex-flow: column;

            .delayed-service {
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;
                font-size: large;
            }
        }
    }
}

@mixin stretched-from-left-button {
    button {
        background: #fff;
        color: #333;
        border: none;
        border-radius: 0 30px 30px 0;
        width: 97%;
        padding: 0.5rem;
        // font-weight: 500;
        box-shadow: 2px 0 7px -1px rgba(0, 0, 0, 0.51);
    }

    button:hover {
        transform: scale3d(1.03, 1, 1);
        font-weight: 500;
    }
}

@mixin header-container {
    .header-container {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 0 0;
        height: 3rem;
        color: map-get($primary-theme, 900);
        background: map-get($default-theme, A300);
        width: 100%;

        .header-name {
            text-align: center;
            font-size: x-large;
        }

        mat-icon {
            &:not(.close-button) {
                margin: 0 0 0.5rem 0.5rem;

                body.ltr & {
                    margin: 0 0.5rem 0.5rem 0;
                }
            }
        }
    }
}

@mixin primary-continue-button {
    .primary-continue-button {
        @include card-shadow;
        font-size: large;
        display: flex;
        justify-content: center;
        background-color: map-get($primary-theme, 100);
        border-radius: 0.5rem;
        padding: 1rem;
        margin-top: 0.5rem;
        width: 30%;
        cursor: pointer;
    }
}

@mixin gift-card-shop-continue-button {
    height: 2.75rem;
    width: 100%;
    margin-top: 0.5rem;
    line-height: 2.75rem;
    font-size: 1.2rem;
    font-weight: 100;
    cursor: pointer;

    &.mat-mdc-button-disabled {
        background: map-get($default-theme, 100);
        color: map-get($default-theme, 500);
    }

    .spacer {
        width: 2.75rem;
    }

    .mdc-button__label {
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 2.75rem;
    }

    button.mat-icon-button ::ng-deep {
        height: 2.75rem;
        width: 2.75rem;

        .mat-mdc-button-focus-overlay {
            display: none !important;
        }

        .mdc-button__label {
            justify-content: flex-end;
        }
    }

    @media (prefers-color-scheme: light) {
        &.mat-mdc-button-disabled {
            background: rgba(200, 200, 200, 0.5) !important; // border: 1px solid map-get($default-theme, 500);
        }
    }
}

@mixin shiny-button {
    .shiny {
        height: 4rem;
        width: 100%;
        background: #59cce0;
        z-index: 0;
        position: relative;
        border-radius: 6Px;
        overflow: hidden;
        background-color: var(--primary-theme-500);
        font-size: 24px;
        display: flex;
        justify-content: space-around;
        color: white;
        align-items: center;

        .arrows {
            margin-bottom: 10px;
        }
    }

    /* Shine */
    .shiny.shiny-arrows:after {
        border-radius: 6Px;
        height: 4rem;
        width: 100%;
        content: "";
        top: 0;
        transform: translateX(100%);
        position: absolute;
        z-index: 1;
        animation: slide 2s infinite 2s;
        background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 10%,
        ); /* FF3.6+ */
        background: -webkit-gradient(
            linear,
            right top,
            left top,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(50%, rgba(255, 255, 255, 0.8)),
            color-stop(10%, rgba(128, 186, 232, 0)),
        ); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(
            right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 10%,
        ); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(
            right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(125, 185, 232, 0) 10%
        ); /* Opera 11.10+ */
        background: -ms-linear-gradient(
            right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(125, 185, 232, 0) 10%
        ); /* IE10+ */
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(125, 185, 232, 0) 10%
        ); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    }

    /* animation */
    @keyframes slide {
        100% {
            transform: translateX(var(--translate100));
        }
        0% {
            transform: translateX(var(--translate0));
        }
    }
}



/* TOASTR STYLING */

@import 'toastr.scss';
